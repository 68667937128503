import Anser from 'anser'
import AnsiParser from 'node-ansiparser'

export default {
  data: () => ({
    concourseContent: {},
    concourseLastEventId: -1,
  }),
  methods: {
    processEvent (event) {
      // We already handled this event
      if (Number(event.lastEventId) <= Number(this.concourseLastEventId)) return

      const ciEvent = JSON.parse(event.data)
      this.concourseLastEventId = event.lastEventId

      if (ciEvent.data.hasOwnProperty('origin')) {
        // If the id doesn't exist in content object
        if (!this.concourseContent.hasOwnProperty(ciEvent.data.origin.id)) {
          // We need to use this.$set to make this object reactive
          this.$set(this.concourseContent, ciEvent.data.origin.id, {
            ansi: [],
            ansiCursor: { row: 0, col: 0 },
            task: {},
            resource: {},
            time: {
              init_at: null,
              started_at: null,
              finished_at: null,
            },
            worker: null,
            streaming: [],
          })
        }

        const originId = ciEvent.data.origin.id

        // If it's an error event
        if (ciEvent.event === 'error') {
          // And there is a message associated
          if (ciEvent.data.hasOwnProperty('message')) {
            // If there isn't yet an error property on our id object, create one
            if (!this.concourseContent[originId].error) {
              this.$set(this.concourseContent[originId], 'error', '')
              this.concourseContent[originId].time.finished_at = ciEvent.data.time
            }
            this.concourseContent[originId].error += Anser.ansiToHtml(ciEvent.data.message, { use_classes: true })
          }
          return
        }

        // If it's a selected-worker event
        if (ciEvent.event === 'selected-worker') {
          this.concourseContent[originId].worker = _.get(ciEvent, 'data.selected_worker')
          return
        }

        // If it's a streaming-volume event
        if (ciEvent.event === 'streaming-volume') {
          const streaming = { volume: _.get(ciEvent, 'data.volume'), from: _.get(ciEvent, 'data.source_worker') }
          this.concourseContent[originId].streaming.push(streaming)
          return
        }

        // If it's a log event
        if (ciEvent.event === 'log') {
          // And there is a payload associated
          if (ciEvent.data.hasOwnProperty('payload')) {
            this.ansiTerminal(originId, ciEvent)
          }
          return
        }

        // If the event is an image-get or image-check
        if (['image-check', 'image-get'].includes(ciEvent.event)) {
          if (!this.concourseContent[originId].hasOwnProperty('plan')) {
            this.concourseContent[originId].plan = {}
          }
          this.concourseContent[originId].plan[ciEvent.event] = ciEvent.data.plan
          return
        }

        // If a task or a get or put resource is initialized
        if (['initialize-task', 'initialize-put', 'initialize-get'].includes(ciEvent.event)) {
          this.concourseContent[originId].time.init_at = ciEvent.data.time
          return
        }

        // If a check is initialized
        if (['initialize-check'].includes(ciEvent.event)) {
          this.concourseContent[originId].time.init_at = ciEvent.data.time
          this.concourseContent[originId].time.started_at = ciEvent.data.time
          return
        }

        // If a resource is started
        if (['start-put', 'start-get', 'start'].includes(ciEvent.event)) {
          this.concourseContent[originId].resource = { version: {}, metadata: [], started: true, finished: false, exitStatus: undefined }
          this.concourseContent[originId].time.started_at = ciEvent.data.time
          return
        }

        // If a task is started
        if (ciEvent.event === 'start-task') {
          this.concourseContent[originId].task = { started: true, finished: false }
          this.concourseContent[originId].time.started_at = ciEvent.data.time
          return
        }

        // If it is a check finished
        if (ciEvent.event === 'finish') {
          this.concourseContent[originId].resource.started = true
          this.concourseContent[originId].resource.finished = true
          this.concourseContent[originId].resource.exitStatus = ciEvent.data.succeeded ? 0 : 1
          this.concourseContent[originId].time.finished_at = ciEvent.data.time
          return
        }

        // If a task is finished
        if (ciEvent.event === 'finish-task') {
          this.concourseContent[originId].task.started = true
          this.concourseContent[originId].task.finished = true
          this.concourseContent[originId].task.exitStatus = ciEvent.data.exit_status
          this.concourseContent[originId].time.finished_at = ciEvent.data.time
          return
        }

        // If a get or put resource is finished
        if (['finish-put', 'finish-get'].includes(ciEvent.event)) {
          this.concourseContent[originId].resource.started = true
          this.concourseContent[originId].resource.finished = true

          if (ciEvent.data.hasOwnProperty('metadata')) {
            this.concourseContent[originId].resource.metadata = ciEvent.data.metadata
          }
          if (ciEvent.data.hasOwnProperty('version')) {
            this.concourseContent[originId].resource.version = ciEvent.data.version
          }
          this.concourseContent[originId].resource.exitStatus = ciEvent.data.exit_status
          this.concourseContent[originId].time.finished_at = ciEvent.data.time
        }
      }
    },

    ansiTerminal (originId, ciEvent) {
      // We need to access the vue instance from the terminal
      const self = this
      const adjustLog = (log, cursor) => {
        self.$set(self.concourseContent[originId].ansi, cursor.row, {
          time: ciEvent.data.time,
          log,
          id: `${originId}-${cursor.row}`,
        })
      }
      const terminal = {
        inst_p (s) {
          const { ansi, ansiCursor } = self.concourseContent[originId]
          const line = _.get(ansi[ansiCursor.row], 'log', '')

          if (!line) {
            adjustLog(s, ansiCursor)
          } else if (ansiCursor.col === 0) {
            adjustLog(s + line.substring(s.length), ansiCursor)
          } else if (line.length < ansiCursor.col) {
            const len = ansiCursor.col - (line.length - 1)
            adjustLog(ansi[ansiCursor.row].log + new Array(len).join(' ') + s, ansiCursor)
          } else if (line.length === ansiCursor.col) {
            adjustLog(ansi[ansiCursor.row].log + s, ansiCursor)
          } else {
            const before = line.substring(0, ansiCursor.col)
            const after = line.substring(ansiCursor.col + s.length)
            adjustLog(before + s + after, ansiCursor)
          }
          ansiCursor.col += s.length
          if (ansiCursor.col < 0) ansiCursor.col = 0
          if (ansiCursor.row < 0) ansiCursor.row = 0
          self.concourseContent[originId].ansiCursor = ansiCursor
        },
        inst_x (flag) {
          const { ansi, ansiCursor } = self.concourseContent[originId]
          const tabWidth = 8
          switch (flag) {
            case '\n':
              ansiCursor.row++
              ansiCursor.col = 0
              adjustLog('', ansiCursor)
              break
            case '\r':
              ansiCursor.col = 0
              break
            case '\t':
              ansiCursor.col += tabWidth - (ansi[ansiCursor.row].log.length % tabWidth)
              break
            case '\x0b':
              this.inst_x('\n')
              break
            case '\x0c':
              this.inst_x('\n')
              break
            default:
              console.error('There is an invisible ANSI character that is not handled: inst_x:', flag.charCodeAt(0))
          }
          self.concourseContent[originId].ansiCursor = ansiCursor
        },
        inst_c (collected, params, flag) {
          const { ansi, ansiCursor } = self.concourseContent[originId]
          const value = params[0] === 0 ? 1 : params[0]
          switch (flag) {
            case 'A': // UP
              ansiCursor.row -= value
              break
            case 'B': // Down
              ansiCursor.row += value
              break
            case 'C': // forward
              ansiCursor.col += value
              break
            case 'D': // Back
              ansiCursor.col -= value
              break
            case 'E': // Cursor Next Line
              ansiCursor.col = 0
              ansiCursor.row += value
              break
            case 'F': // Cursor Previous Line
              ansiCursor.col = 0
              ansiCursor.row -= value
              break
            case 'G': // Cursor Horizontal Absolute
              ansiCursor.col = value
              break
            case 'H': // Cursor Position
              ansiCursor.row = params[0]
              ansiCursor.col = params[1]
              break
            case 'J': // Erase in Display
              if (params[0] === 0 || !params[0]) {
                self.concourseContent[originId].ansi.length = ansiCursor.row + 1
              }
              break
            case 'K': // Erase in Line
              if (params[0] === 1) {
                adjustLog(_.get(ansi[ansiCursor.row], 'log', '').substring(ansiCursor.col, _.get(ansi[ansiCursor.row], 'log', '').length), ansiCursor)
              } else if (params[0] === 2) {
                adjustLog('', ansiCursor)
              } else {
                adjustLog(_.get(ansi[ansiCursor.row], 'log', '').substring(0, ansiCursor.col), ansiCursor)
              }
              break
            case 'm': { // Style the line
              /*
              * cheating for colors, let's write it as text, Anser will handle the colorization on rendering.
              * this could lead to some ANSI bugs for code that is not erasing the whole line but only moving
              * the cursor to previous character. But on 95% of progress bar and stuff, it's deleting the whole
              * line to rewrite it completely so this implementation will do the trick :)
              */
              if (Array.isArray(params)) {
                params = params.join(';')
              }
              this.inst_p('\u001b[' + collected + params + flag)
              break
            }
            case 'l': // DECTCEM Hides the cursor
              break
            case 'h': // DECTCEM Shows the cursor
              break
            default:
              console.error('There is an invisible ANSI character that is not handled: inst_c:', collected, params, flag)
          }
          if (ansiCursor.col < 0) ansiCursor.col = 0
          if (ansiCursor.row < 0) ansiCursor.row = 0
          self.concourseContent[originId].ansiCursor = ansiCursor
        },
      }
      const parser = new AnsiParser(terminal)
      parser.parse(ciEvent.data.payload)
    },
    setupStream () {
      let es = null
      const url = `${process.env.VUE_APP_API_URL}/organizations/${this.orgCanonical}/projects/${this.projectCanonical}/pipelines/${this.pipelineCanonical}/builds/${this.buildId}/events?api_key=${this.$store.state.auth.jwt}`
      es = new EventSource(url)
      es.addEventListener('error', (event) => {
        /*
        * TODO: Temporary patch while waiting for https://github.com/cycloidio/youdeploy-http-api/issues/906
        * This is closing the stream instead of reconnecting it. Then it call a new setup of the stream
        * to reset the lasteventid.
        */
        if (_.some([EventSource.CONNECTING, EventSource.CLOSED], (status) => status === _.get(event.target, 'readyState'))) {
          es.close()
          fetch(url)
            .then((response) => {
              if (response.status !== 404) {
                this.setupStream()
              } else {
                this.getBuildFromAPI()
              }
              return response
            })
            .catch((error) => {
              console.error('There has been a problem with your fetch operation:', error)
            })
        }
      })

      es.addEventListener('event', (event) => {
        this.processEvent(event)
      }, false)

      es.addEventListener('end', (event) => {
        es.close()
        if (this.jobCanonical) {
          this.getBuildFromAPI()
          this.getBuildsFromAPI()
        }
      }, false)

      es.addEventListener('open', (event) => {}, false)
    },
  },
}
